/* eslint-disable no-magic-numbers */

export const DEFAULT_COLUMNS = {
    amountField: "",
    externalReferenceField: "",
    externalInvoiceIdField: "",
    productNameField: "",
    priceField: "",
    weightField: "",
    lengthField: "",
    heightField: "",
    widthField: "",
};

export const DHL_DEFAULT_COLUMNS = {
    amountField: "Pieces",
    externalReferenceField: "Shipment Reference 2",
    externalInvoiceIdField: "Invoice Identifier",
    productNameField: "Product Name",
    priceField: "Total Amount",
};

export const BPOST_DEFAULT_COLUMNS = {
    amountField: "Aantal",
    externalReferenceField: "Subgroep",
    productNameField: "Omschrijving",
    priceField: "Totaalbedrag ex BTW",
};

export const MYPARCEL_DEFAULT_COLUMNS = {
    externalReferenceField: "barcode",
    productNameField: "product",
    priceField: "prijs excl. BTW",
};

export const PARCELPRO_DEFAULT_COLUMNS = {
    amountField: "Aantal",
    externalReferenceField: "Barcode",
    productNameField: "Omschrijving",
    priceField: "Verkooptarief",
    externalInvoiceIdField: "Kenmerk",
};

export enum E_USER_TYPES {
    NON_INTEGRATED_USER = 0,
    VIABODE = 2,
    VIADC = 3,
    VIAGEBRUIKER = 4,
    VIAWEBSHOP = 5,
    EXTERNAL_WEBSHOP = 6,
    EXTERNAL_PARTY = 7,
    MODERATOR = 8,
    GLOBAL_ADMIN = 9,
    TRANSPORTER = 11,
    SUPPORT = 12,
}

export enum E_PACKAGE_TYPES {
    STANDARD_PACKAGE_L10KG = 0,
    STANDARD_PACKAGE_H10KG = 1,
    STANDARD_PACKAGE_BRIEF = 2,
    STANDARD_SAME_DAY_DELIVERY = 3,
    STANDARD_PACKAGE_H20KG = 4,
    STANDARD_PACKAGE_L15KG = 5,
    STANDARD_PACKAGE_H15KG = 6,
    STANDARD_PACKAGE_H30KG = 7,
    STANDARD_PACKAGE_WEBSHOP = 8,
    RETOUR_PACKAGE = 9,
    CONSUMER_PACKAGE = 10,
    STANDARD_PACKAGE_ENVELOPE = 11,
}

export enum CAPABILITIES {
    INSURANCE = "INSURANCE",
    ID_CHECK = "ID_CHECK",
    NO_NEIGHBOURS = "NO_NEIGHBOURS",
    PROOF_OF_DELIVERY = "PROOF_OF_DELIVERY",
    SAME_DAY = "SAME_DAY",
    PREMIUM = "PREMIUM",
    EVENING_DELIVERY = "EVENING_DELIVERY",
}

export enum E_TRACKING_STATUSES {
    PACKAGE_ADDED_TO_SYSTEM = 0,
    PACKAGE_SCANNED_BY_DC = 1,
    PACKAGE_SCANNED_BY_VIABODE = 2,
    PACKAGE_SCANNED_BY_VIAPUNT = 3,
    PACKAGE_GIVEN_TO_VIAGEBRUIKER = 4,
    PACKAGE_PICKED_UP_BY_VIAGEBRUIKER = 5,
    PACKAGE_VERIFIED_BY_VIAGEBRUIKER = 6,
    PACKAGE_GIVEN_TO_VIABODE = 7,
    PACKAGE_GIVEN_TO_VIAPUNT = 8,
    PACKAGE_GIVEN_TO_VIADC = 9,
    PACKAGE_GIVEN_TO_EXTERNAL = 10,
}

export enum E_PAPER_TYPES {
    STANDARD_PRINTER_A4_PAPER = 0,
    LABELPRINTER_A6_PAPER = 1,
}

export const DEFAULT_PAGE_SIZE = 30;
export const PAGE_SIZE_10 = 10;

export const PACKAGE_TYPES_TO_TEXT = [
    "Minder dan 10 kg",
    "Tussen 10 - 20 kg",
    "Brievenbuspakket",
    "Same-day Delivery pakket",
    "Meer dan 20 kg",
    "0 - 15kg",
    "15 - 30kg",
    "> 30kg",
    "Webshoppakket",
    "Retourpakket",
    "Consumentpakket",
];

export const PACKAGE_TYPES = [
    {
        name: "Brievenbuspakket",
        id: 2,
    },
    {
        name: "Pakket tot 15kg",
        id: 5,
    },
    {
        name: "Pakket 15-30kg",
        id: 6,
    },
    {
        name: "Webshoppakket",
        id: 8,
    },
];

export const SERVICE_TYPES = [
    {
        name: "Verzekering",
        id: 0,
    },
    {
        name: "Aangetekend",
        id: 1,
    },
    {
        name: "18+ Check",
        id: 2,
    },
    {
        name: "Niet bij de buren",
        id: 3,
    },
    {
        name: "Zelfde dag",
        id: 4,
    },
    {
        name: "Bedrijfstoeslag",
        id: 5,
    },
];

export const USER_TYPE_TO_TEXT = [
    "Klant",
    "ViaBode",
    "ViaDC",
    "ViaGebruiker",
    "Webshop",
    "Niet geïntegreerde webshop",
    "Externe partij",
    "Moderator",
    "Admin",
];

export const STATUS_TO_TEXT = [
    "Pakket toegevoegd aan systeem",
    "Pakket binnen op ViaDC",
    "Pakket in bezit van ViaBode",
    "Pakket in bezit van ViaPunt",
    "Pakket afgegeven aan Klant",
    "Pakket opgehaald door Klant",
    "Pakket geverifieerd door Klant",
    "Pakket afgegeven aan ViaBode",
    "Pakket afgegeven aan ViaPunt",
    "Pakket afgegeven aan viaDC",
    "Pakket afgegeven aan externe bode",
];

export const PAPER_TYPES_TO_TEXT = ["Standaard printer (A4)", "Labelprinter (A6)"];

export const E_INVENTORY_SCAN_TYPES = {
    EXTERNAL: 0,
    CUSTOMER: 1,
};

export const E_INVENTORY_PICKUP_TYPES = {
    EXTERNAL: 0,
    CUSTOMER: 1,
};

export const BIC_CODES = {
    ABNA: "ABNANL2A",
    ADYB: "ADYBNL2A",
    AEGO: "AEGONL2U",
    ANDL: "ANDLNL2A",
    ARBN: "ARBNNL22",
    ARSN: "ARSNNL21",
    ASNB: "ASNBNL21",
    ATBA: "ATBANL2A",
    BCDM: "BCDMNL22",
    BCIT: "BCITNL2A",
    BICK: "BICKNL2A",
    BINK: "BINKNL21",
    BKCH: "BKCHNL2R",
    BKMG: "BKMGNL2A",
    BLGW: "BLGWNL21",
    BMEU: "BMEUNL21",
    BNDA: "BNDANL2A",
    BNGH: "BNGHNL2G",
    BNPA: "BNPANL2A",
    BOFA: "BOFANLNX",
    BOFS: "BOFSNL21002",
    BOTK: "BOTKNL2X",
    BUNQ: "BUNQNL2A",
    CHAS: "CHASNL2X",
    CITC: "CITCNL2A",
    CITI: "CITINL2X",
    COBA: "COBANL2X",
    DEUT: "DEUTNL2A",
    DHBN: "DHBNNL2R",
    DLBK: "DLBKNL2A",
    DNIB: "DNIBNL2G",
    EBUR: "EBURNL21",
    FBHL: "FBHLNL2A",
    FLOR: "FLORNL2A",
    FRGH: "FRGHNL21",
    FRNX: "FRNXNL2A",
    FTSB: "ABNANL2A",
    FVLB: "FVLBNL22",
    GILL: "GILLNL2A",
    HAND: "HANDNL2A",
    HHBA: "HHBANL22",
    HSBC: "HSBCNL2A",
    ICBK: "ICBKNL2A",
    INGB: "INGBNL2A",
    ISAE: "ISAENL2A",
    ISBK: "ISBKNL2A",
    KABA: "KABANL2A",
    KASA: "KASANL2A",
    KNAB: "KNABNL2H",
    KOEX: "KOEXNL2A",
    KRED: "KREDNL2X",
    LOCY: "LOCYNL2A",
    LOYD: "LOYDNL2A",
    LPLN: "LPLNNL2F",
    MHCB: "MHCBNL2A",
    MOYO: "MOYONL21",
    NNBA: "NNBANL2G",
    NWAB: "NWABNL2G",
    PCBC: "PCBCNL2A",
    RABO: "RABONL2U",
    RBRB: "RBRBNL21",
    SNSB: "SNSBNL2A",
    SOGE: "SOGENL2A",
    TEBU: "TEBUNL2A",
    TRIO: "TRIONL2U",
    UBSW: "UBSWNL2A",
    UGBI: "UGBINL2A",
    VOWA: "VOWANL21",
    ZWLB: "ZWLBNL21",
};

export interface ReasonedValidationError {
    fields: string[];
    reason: string;
}

export const ERROR_TRANSLATION = {
    invalid: {
        default: "De waarde is ongeldig",
        postcode: "De postcode is ongeldig",
        email: "Het emailadres is ongeldig",
        phone: "Het telefoonnummer is ongeldig",
        housenr: "Het huisnummer is ongeldig",
        webshop_connector_config: "De configuratie is ongeldig",
        iban: "Het IBAN is ongeldig",
        bic: "De BIC is ongeldig",
        ascription: "De toeschrijving is ongeldig",
        domain: "Het domein is ongeldig",
    },
    required: {
        default: "Deze waarde is verplicht",
        email: "Het emailadres is verplicht",
    },
    incorrect_type: {
        default: "De waarde heeft een verkeerd format",
        housenr: "Het huisnummer moet een nummer zijn",
        kvk: "Het KVK nummer moet een nummer zijn",
        exactid: "Het Exact relatie nummer moet een nummer zijn",
    },
    too_short: {
        default: "De waarde is te kort",
        postcode: "De postcode is te kort",
        phone: "Het telefoonnummer is te kort",
        iban: "Het IBAN is te kort",
        bic: "De BIC is te kort",
        btw: "Het BTW nummer is te kort",
        kvk: "Het KVK nummer is te kort",
        exactid: "Het Exact relatie nummer is te kort",
        ascription: "De toeschrijving is te kort",
    },
    too_long: {
        default: "De waarde is te lang",
        postcode: "De postcode is te lang",
        phone: "Het telefoonnummer is te lang",
        iban: "Het IBAN is te lang",
        bic: "De BIC is te lang",
        kvk: "Het KVK nummer is te lang",
        exactid: "Het Exact relatie nummer is te lang",
        ascription: "De toeschrijving is te lang",
        domain: "Het domein is te lang",
    },
    not_allowed: {
        default: "U heeft iets gedaan waar u geen rechten voor heeft",
        external: "U heeft geen rechten om deze vervoerder te gebruiken",
    },
    illegal_combination: {
        default: "De combinatie tussen velden bestaat niet",
        postcode: "De combinatie van postcode en huisnummer bestaat niet",
    },
    default: "De waarde is ongeldig",
};

export enum PARCEL_TYPE {
    MAILBOX = "MAILBOX",
    NORMAL = "NORMAL",
    HEAVY = "HEAVY",
}

export const CENTS_PER_EURO = 100;

export const FULL_PERCENTAGES = 100;

export const COUNTRIES = [
    { code: "AD", name: "Andorra" },
    { code: "AE", name: "Verenigde Arabische Emiraten" },
    { code: "AF", name: "Afghanistan" },
    { code: "AG", name: "Antigua en Barbuda" },
    { code: "AI", name: "Anguilla" },
    { code: "AL", name: "Albanië" },
    { code: "AM", name: "Armenië" },
    { code: "AO", name: "Angola" },
    { code: "AQ", name: "Antarctica" },
    { code: "AR", name: "Argentinië" },
    { code: "AS", name: "Amerikaans-Samoa" },
    { code: "AT", name: "Oostenrijk" },
    { code: "AU", name: "Australië" },
    { code: "AW", name: "Aruba" },
    { code: "AX", name: "Åland" },
    { code: "AZ", name: "Azerbeidzjan" },
    { code: "BA", name: "Bosnië-Herzegovina" },
    { code: "BB", name: "Barbados" },
    { code: "BD", name: "Bangladesh" },
    { code: "BE", name: "België" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BG", name: "Bulgarije" },
    { code: "BH", name: "Bahrein" },
    { code: "BI", name: "Burundi" },
    { code: "BJ", name: "Benin" },
    { code: "BL", name: "Saint Barthélemy" },
    { code: "BM", name: "Bermuda" },
    { code: "BN", name: "Brunei Darussalam" },
    { code: "BO", name: "Bolivië" },
    { code: "BQ", name: "Bonaire, Sint Eustatius en Saba" },
    { code: "BR", name: "Brazilië" },
    { code: "BS", name: "Bahama's" },
    { code: "BT", name: "Bhutan" },
    { code: "BV", name: "Bouvet Eiland" },
    { code: "BW", name: "Botswana" },
    { code: "BY", name: "Wit-Rusland" },
    { code: "BZ", name: "Belize" },
    { code: "CA", name: "Canada" },
    { code: "CC", name: "Cocoseilanden" },
    { code: "CF", name: "Centraal-Afrikaanse Republiek" },
    { code: "CG", name: "Congo" },
    { code: "CH", name: "Zwitserland" },
    { code: "CI", name: "Ivoorkust" },
    { code: "CK", name: "Cookeilanden" },
    { code: "CL", name: "Chili" },
    { code: "CM", name: "Kameroen" },
    { code: "CN", name: "China" },
    { code: "CO", name: "Colombia" },
    { code: "CR", name: "Costa Rica" },
    { code: "CU", name: "Cuba" },
    { code: "CV", name: "Kaapverdië" },
    { code: "CW", name: "Curaçao" },
    { code: "CX", name: "Christmaseiland" },
    { code: "CY", name: "Cyprus" },
    { code: "CZ", name: "Tsjechië" },
    { code: "DE", name: "Duitsland" },
    { code: "DJ", name: "Djibouti" },
    { code: "DK", name: "Denemarken" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominicaanse Republiek" },
    { code: "DZ", name: "Algerije" },
    { code: "EC", name: "Ecuador" },
    { code: "EE", name: "Estland" },
    { code: "EG", name: "Egypte" },
    { code: "EH", name: "Westelijke Sahara" },
    { code: "ER", name: "Eritrea" },
    { code: "ES", name: "Spanje" },
    { code: "ET", name: "Ethiopië" },
    { code: "FI", name: "Finland" },
    { code: "FJ", name: "Fiji" },
    { code: "FK", name: "Falklandeilanden" },
    { code: "FM", name: "Micronesië, Federale Staten" },
    { code: "FO", name: "Faeröer" },
    { code: "FR", name: "Frankrijk" },
    { code: "GA", name: "Gabon" },
    { code: "GB", name: "Verenigd Koninkrijk" },
    { code: "GD", name: "Grenada" },
    { code: "GE", name: "Georgië" },
    { code: "GF", name: "Frans-Guyana" },
    { code: "GG", name: "Guernsey" },
    { code: "GH", name: "Ghana" },
    { code: "GI", name: "Gibraltar" },
    { code: "GL", name: "Groenland" },
    { code: "GM", name: "Gambia" },
    { code: "GN", name: "Guinea" },
    { code: "GP", name: "Guadeloupe" },
    { code: "GQ", name: "Equatoriaal-Guinea" },
    { code: "GR", name: "Griekenland" },
    { code: "GS", name: "Zuid-Georgia en de Zuidelijke Sandwicheilanden" },
    { code: "GT", name: "Guatemala" },
    { code: "GU", name: "Guam" },
    { code: "GW", name: "Guinee-Bissau" },
    { code: "GY", name: "Guyana" },
    { code: "HK", name: "Hong Kong" },
    { code: "HM", name: "Heard en McDonaldeilanden" },
    { code: "HN", name: "Honduras" },
    { code: "HR", name: "Kroatië" },
    { code: "HT", name: "Haïti" },
    { code: "HU", name: "Hongarije" },
    { code: "ID", name: "Indonesië" },
    { code: "IE", name: "Ierland" },
    { code: "IL", name: "Israël" },
    { code: "IM", name: "Man Eiland" },
    { code: "IN", name: "India" },
    { code: "IO", name: "Brits Indische oceaan" },
    { code: "IQ", name: "Irak" },
    { code: "IR", name: "Iran" },
    { code: "IS", name: "IJsland" },
    { code: "IT", name: "Italië" },
    { code: "JE", name: "Jersey" },
    { code: "JM", name: "Jamaica" },
    { code: "JO", name: "Jordanië" },
    { code: "JP", name: "Japan" },
    { code: "KE", name: "Kenia" },
    { code: "KG", name: "Kirgizstan" },
    { code: "KH", name: "Cambodja" },
    { code: "KI", name: "Kiribati" },
    { code: "KM", name: "Comoren" },
    { code: "KN", name: "Saint Kitts en Nevis" },
    { code: "KP", name: "Noord-Korea" },
    { code: "KR", name: "Zuid-Korea" },
    { code: "KW", name: "Koeweit" },
    { code: "KY", name: "Kaaimaneilanden" },
    { code: "KZ", name: "Kazachstan" },
    { code: "LA", name: "Laos" },
    { code: "LB", name: "Libanon" },
    { code: "LC", name: "Saint Lucia" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LK", name: "Sri Lanka" },
    { code: "LR", name: "Liberia" },
    { code: "LS", name: "Lesotho" },
    { code: "LT", name: "Litouwen" },
    { code: "LU", name: "Luxemburg" },
    { code: "LV", name: "Letland" },
    { code: "LY", name: "Libië" },
    { code: "MA", name: "Marokko" },
    { code: "MC", name: "Monaco" },
    { code: "MD", name: "Moldavië" },
    { code: "ME", name: "Montenegro" },
    { code: "MF", name: "Sint-Maarten" },
    { code: "MG", name: "Madagaskar" },
    { code: "MH", name: "Marshalleilanden" },
    { code: "MK", name: "Noord-Macedonië" },
    { code: "ML", name: "Mali" },
    { code: "MM", name: "Myanmar" },
    { code: "MN", name: "Mongolië" },
    { code: "MO", name: "Macao" },
    { code: "MP", name: "Noordelijke Marianen" },
    { code: "MQ", name: "Martinique" },
    { code: "MR", name: "Mauritanië" },
    { code: "MS", name: "Montserrat" },
    { code: "MT", name: "Malta" },
    { code: "MU", name: "Mauritius" },
    { code: "MV", name: "Maldiven" },
    { code: "MW", name: "Malawi" },
    { code: "MX", name: "Mexico" },
    { code: "MY", name: "Maleisië" },
    { code: "MZ", name: "Mozambique" },
    { code: "NA", name: "Namibië" },
    { code: "NC", name: "Nieuw-Caledonië" },
    { code: "NE", name: "Niger" },
    { code: "NF", name: "Norfolk" },
    { code: "NG", name: "Nigeria" },
    { code: "NI", name: "Nicaragua" },
    { code: "NL", name: "Nederland" },
    { code: "NO", name: "Noorwegen" },
    { code: "NP", name: "Nepal" },
    { code: "NR", name: "Nauru" },
    { code: "NU", name: "Niue" },
    { code: "NZ", name: "Nieuw-Zeeland" },
    { code: "OM", name: "Oman" },
    { code: "PA", name: "Panama" },
    { code: "PE", name: "Peru" },
    { code: "PF", name: "Frans-Polynesië" },
    { code: "PG", name: "Papoea-Nieuw-Guinea" },
    { code: "PH", name: "Filipijnen" },
    { code: "PK", name: "Pakistan" },
    { code: "PL", name: "Polen" },
    { code: "PM", name: "Saint-Pierre en Miquelon" },
    { code: "PN", name: "Pitcairn" },
    { code: "PR", name: "Puerto Rico" },
    { code: "PS", name: "Palestina" },
    { code: "PT", name: "Portugal" },
    { code: "PW", name: "Palau" },
    { code: "PY", name: "Paraguay" },
    { code: "QA", name: "Qatar" },
    { code: "RE", name: "Réunion" },
    { code: "RO", name: "Roemenië" },
    { code: "RS", name: "Servië" },
    { code: "RU", name: "Rusland" },
    { code: "RW", name: "Rwanda" },
    { code: "SA", name: "Saudi-Arabië" },
    { code: "SB", name: "Salomonseilanden" },
    { code: "SC", name: "Seychellen" },
    { code: "SD", name: "Soedan" },
    { code: "SE", name: "Zweden" },
    { code: "SG", name: "Singapore" },
    { code: "SH", name: "Sint-Helena" },
    { code: "SI", name: "Slovenië" },
    { code: "SJ", name: "Spitsbergen en Jan Mayen" },
    { code: "SK", name: "Slowakije" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SM", name: "San Marino" },
    { code: "SN", name: "Senegal" },
    { code: "SO", name: "Somalië" },
    { code: "SR", name: "Suriname" },
    { code: "SS", name: "Zuid-Soedan" },
    { code: "ST", name: "São Tomé en Principe" },
    { code: "SV", name: "El Salvador" },
    { code: "SX", name: "Sint Maarten" },
    { code: "SY", name: "Syrië" },
    { code: "SZ", name: "Ngwane, Koninkrijk Eswatini" },
    { code: "TC", name: "Turks- en Caicoseilanden" },
    { code: "TD", name: "Tsjaad" },
    { code: "TF", name: "Franse Zuidelijke Gebieden" },
    { code: "TG", name: "Togo" },
    { code: "TH", name: "Thailand" },
    { code: "TJ", name: "Tadzjikistan" },
    { code: "TK", name: "Tokelau" },
    { code: "TL", name: "Timor Leste" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TN", name: "Tunesië" },
    { code: "TO", name: "Tonga" },
    { code: "TR", name: "Turkije" },
    { code: "TT", name: "Trinidad en Tobago" },
    { code: "TV", name: "Tuvalu" },
    { code: "TW", name: "Taiwan" },
    { code: "TZ", name: "Tanzania, Verenigde Republiek" },
    { code: "UA", name: "Oekraïne" },
    { code: "UG", name: "Oeganda" },
    { code: "UM", name: "Ver afgelegen eilandjes van de Verenigde Staten" },
    { code: "US", name: "Verenigde Staten" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Oezbekistan" },
    { code: "VA", name: "Heilige Stoel" },
    { code: "VC", name: "Saint Vincent en de Grenadines" },
    { code: "VE", name: "Venezuela" },
    { code: "VG", name: "Maagdeneilanden, Britse" },
    { code: "VI", name: "Maagdeneilanden, Amerikaanse" },
    { code: "VN", name: "Vietnam" },
    { code: "VU", name: "Vanuatu" },
    { code: "WF", name: "Wallis en Futuna" },
    { code: "WS", name: "Samoa" },
    { code: "XK", name: "Kosovo" },
    { code: "YE", name: "Jemen" },
    { code: "YT", name: "Mayotte" },
    { code: "ZA", name: "Zuid-Afrika" },
    { code: "ZM", name: "Zambia" },
    { code: "ZW", name: "Zimbabwe" },
];

export const NO_CUSTOM_DOCUMENT_COUNTRY_CODES = [
    "BE",
    "BG",
    "CY",
    "DK",
    "DE",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HU",
    "IE",
    "IS",
    "IT",
    "HR",
    "LV",
    "LI",
    "LT",
    "LU",
    "NL",
    "AT",
    "PL",
    "PT",
    "RO",
    "SI",
    "SK",
    "CZ",
    "SE",
];
