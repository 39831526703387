import { Observable } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { first } from "rxjs/operators";
import * as FileSaver from "file-saver-es";
import { AuthService } from "../services/auth.service";
import { BackendService } from "../services/backend.service";
import { ExportService } from "../services/export.service";
import { E_USER_TYPES } from "../helpers/constants";
import { Message } from "../models/message";
import { addMessage } from "../store/actions/message.actions";
import { User } from "../models/user.model";
import { ViaWebshop } from "../models/viawebshop.model";
import { PackageFilter } from "../models/packageFilter";
import { setPackageFilter } from "../store/actions/package-filter.actions";

@Component({
    selector: "app-packages",
    templateUrl: "./packages.component.html",
    styleUrls: ["./packages.component.scss"],
})
export class PackagesComponent implements OnInit {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    packageFilter$: Observable<PackageFilter> = this.store.select((state) => state.packageFilter);

    selectedPackages$: Observable<string[]> = this.store.select((state) => state.selectedPackages);

    public hasWebshopConnector: boolean = false;

    constructor(
        private auth: AuthService,
        private backendService: BackendService,
        private exportService: ExportService,
        private router: Router,
        private store: Store<{
            messages: Message[];
            currentUser: User;
            selectedPackages: string[];
            packageFilter: PackageFilter;
        }>,
        private activatedRoute: ActivatedRoute,
    ) {
        this.currentUser$.subscribe((user: User) => {
            if (user instanceof ViaWebshop) {
                this.hasWebshopConnector = !!user.webshopConnectorType;
            }
        });
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.store.dispatch(setPackageFilter({ filter: PackageFilter.fromURLParams(params) }));
        });
    }

    get canAddPackages() {
        return (
            this.auth.getAccountRole() !== E_USER_TYPES.VIABODE &&
            this.auth.getAccountRole() !== E_USER_TYPES.SUPPORT
        );
    }

    get canImportPackages() {
        return this.auth.getAccountRole() === E_USER_TYPES.VIAWEBSHOP && this.hasWebshopConnector;
    }

    get canExportPackages() {
        return this.auth.getAccountRole() !== E_USER_TYPES.VIAGEBRUIKER;
    }

    csvUpload() {
        this.router.navigateByUrl("/packages(modal:csv-upload)");
    }

    exportPackages() {
        const filter$ = this.packageFilter$.pipe(first(() => true, new PackageFilter()));
        filter$.subscribe((filter) => {
            this.exportService.downloadExport(filter).subscribe((data) => {
                const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                FileSaver.saveAs(blob, `"export-packages-${new Date().toISOString}.csv"`);
            });
        });
    }

    importPackages() {
        this.backendService.importPackages().subscribe((count) => {
            let message: Message;
            if (count === 0) {
                message = Message.createNotification(
                    "Geen nieuwe pakketen",
                    "Er zijn geen nieuwe pakketen gevonden in het webshop systeem",
                );
            } else {
                message = Message.createSuccessMessage(
                    "Nieuwe pakketen geimporteerd",
                    `Er zijn ${count} nieuwe pakketten geimporteerd uit het webshop systeem`,
                );
            }
            this.store.dispatch(addMessage({ message }));
        });
    }

    bulklabelModal() {
        // this.data.changeMessage({ recipient: "bulk-print", content: this.bulkArray });
        this.router.navigateByUrl("/packages(modal:bulk-print)");
    }

    bulkDeleteModal() {
        // this.data.changeMessage({ recipient: "bulk-delete", content: this.bulkArray });
        this.router.navigateByUrl("/packages(modal:bulk-delete)");
    }

    get isSupportUser() {
        return this.auth.getAccountRole() === E_USER_TYPES.SUPPORT;
    }
}
