<form
    [formGroup]="priorityForm"
    (ngSubmit)="onSubmitPriorityRules()"
    class="webshop-priority container"
>
    <h2 class="webshop-priority__title">Prioriteiten</h2>
    <div>
        <table class="table-striped config__table">
            <tr class="config__row">
                <th class="config__header">Vervoerder</th>
                <th class="config__header">Van</th>
                <th class="config__header">Naar</th>
                <th class="config__header">Pakkettype</th>
                <th class="config__header">Acties</th>
            </tr>
            <tbody *ngFor="let config of transformedWebshopConfig">
                <tr class="config__row">
                    <td class="config__data">
                        {{ config.transporterCode }}
                    </td>
                    <td class="config__data">
                        {{ config.countryTo }}
                    </td>
                    <td class="config__data">
                        {{ config.countryFrom }}
                    </td>
                    <td class="config__data" *ngIf="config.parcelType; else allParcelTypes">
                        {{ config.parcelType }}
                    </td>
                    <ng-template #allParcelTypes>
                        <td class="config__data">*</td>
                    </ng-template>
                    <td class="config__data">
                        <div (click)="deleteRow(config)" class="config-inputContainer">
                            <i class="fas fa-times config-inputContainer__action"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr class="config__row" *ngIf="selected">
                    <td class="config__data">
                        <select
                            class="form-control carriers-accordion-form__input"
                            name="transporterCode"
                            formControlName="transporterCode"
                        >
                            <option value="">Vervoerder</option>
                            <option
                                [value]="config.transporterCode"
                                *ngFor="let config of webshopConfig"
                            >
                                {{ config.transporterCode }}
                            </option>
                        </select>
                        <div
                            class="error__section-input"
                            *ngIf="
                                submitted && priorityForm.controls.transporterCode.errors?.required
                            "
                        >
                            <span class="error__errorMessageInput">Vul de vervoerder in</span>
                        </div>
                    </td>
                    <td class="config__data">
                        <select
                            class="form-control carriers-accordion-form__input"
                            name="countryFrom"
                            formControlName="countryFrom"
                        >
                            <option value="">Van</option>
                            <option [value]="country" *ngFor="let country of schengenCountries">
                                {{ country }}
                            </option>
                        </select>
                        <div
                            class="error__section-input"
                            *ngIf="submitted && priorityForm.controls.countryFrom.errors?.required"
                        >
                            <span class="error__errorMessageInput">Vul het land van in</span>
                        </div>
                    </td>
                    <td class="config__data">
                        <select
                            class="form-control carriers-accordion-form__input"
                            name="countryTo"
                            formControlName="countryTo"
                        >
                            <option value="">Naar</option>
                            <option [value]="country" *ngFor="let country of schengenCountries">
                                {{ country }}
                            </option>
                        </select>
                        <div
                            class="error__section-input"
                            *ngIf="submitted && priorityForm.controls.countryTo.errors?.required"
                        >
                            <span class="error__errorMessageInput">Vul het land naar in</span>
                        </div>
                    </td>
                    <td class="config__data">
                        <select
                            class="form-control carriers-accordion-form__input"
                            name="parcelType"
                            formControlName="parcelType"
                        >
                            <option value="">Pakkettype</option>
                            <option [value]="type" *ngFor="let type of PARCEL_TYPES">
                                {{ type }}
                            </option>
                        </select>
                        <div
                            class="error__section-input"
                            *ngIf="submitted && priorityForm.controls.parcelType.errors?.required"
                        >
                            <span class="error__errorMessageInput">Vul het pakkettype in</span>
                        </div>
                    </td>
                    <td class="config__data config__data--action">
                        <div (click)="isSelected()" class="config-inputContainer">
                            <i class="fas fa-times config-inputContainer__action"></i>
                        </div>
                        <div (click)="onSubmitPriorityRules()" class="config-inputContainer">
                            <i class="fas fa-check config-inputContainer__action"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div _ngcontent-klf-c162="" class="row justify-content-md-center p-4">
            <button
                class="col btn btn-primary"
                [disabled]="!priorityForm.valid"
                [ngClass]="priorityForm.valid ? '' : 'inverse'"
                (click)="onSubmitPriorityRules()"
            >
                Opslaan
            </button>
            <button
                class="col btn btn-secondary offset-sm-2"
                (click)="isSelected()"
                [disabled]="selected"
            >
                Nieuw
            </button>
        </div>
    </div>
</form>
